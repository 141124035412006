import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

const VolunteerForm = () => {
  const [step, setStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    age: "",
    gender: "",
    therapySessions: "",
    platformsUsed: "",
    likedMost: "",
    foundMissing: "",
    likedFeatures: "",
    feedback: "",
  });

  useEffect(() => {
    // Check if the form has already been submitted
    const submitted = localStorage.getItem("volunteerSubmitted");
    if (submitted === "true") {
      setIsSubmitted(true);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    // Prepare the data payload for the API
    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.number,
      age: formData.age,
      gender: formData.gender,
      questions_and_answers: [
        {
          ques: "Have you taken therapy sessions before?",
          ans: formData.therapySessions,
        },
        { ques: "Which platforms have you used?", ans: formData.platformsUsed },
        {
          ques: "What did you like the most about them?",
          ans: formData.likedMost,
        },
        { ques: "What did you find missing?", ans: formData.foundMissing },
        {
          ques: "Which feature of ours are you most excited about?",
          ans: formData.likedFeatures,
        },
        { ques: "Feedback", ans: formData.feedback },
      ],
    };

    try {
      const response = await fetch(
        "https://api.rezen.in/api/v1/webpoints/volunteer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setIsSubmitted(true); // Set submission state
        localStorage.setItem("volunteerSubmitted", "true"); // Store submission flag
        toast.success("Thank you for volunteering with us!");
      } else {
        const errorData = await response.json();
        toast.error(errorData.msg || "Something went wrong!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Unable to submit your response. Please try again.");
    }
  };

  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <div className="px-7 py-8 sm:py-11 padding-x md:py-16 flex items-center justify-center min-h-screen bg-[#faf6f4]">
      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        reverseOrder={false}
      />
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-8">
        {isSubmitted ? (
          <div className="text-center">
            <h1 className="text-3xl font-semibold mb-4 text-[#f0703a]">
              Thank You!
            </h1>
            <p className="text-gray-600 mb-6">
              Your feedback has been submitted successfully.
            </p>
            <button
              onClick={handleGoHome}
              className="w-full bg-[#f0703a] text-white py-2.5 rounded-lg font-semibold hover:bg-[#cc6235] transition duration-300"
            >
              Back to Home
            </button>
          </div>
        ) : (
          <>
            <h1 className="text-2xl md:text-3xl font-semibold md:mb-4 mb-2 text-gray-800 text-center">
              Help Us Improve
            </h1>
            <p className="text-gray-600 mb-8 text-center">
              Volunteer with us in beta testing to help make our platform
              better.
            </p>
            <form className="space-y-6">
              {step === 1 && (
                <>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                      placeholder="Enter your name"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="number"
                      value={formData.number}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                      placeholder="Enter your phone number"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Age
                    </label>
                    <input
                      type="number"
                      name="age"
                      value={formData.age}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                      placeholder="Enter your age"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Gender
                    </label>
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="w-full bg-[#f0703a] text-white py-2.5 rounded-lg font-semibold hover:bg-[#cc6235] transition duration-300"
                  >
                    Next
                  </button>
                </>
              )}
              {step === 2 && (
                <>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Have you taken therapy sessions before?
                    </label>
                    <input
                      type="text"
                      name="therapySessions"
                      value={formData.therapySessions}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                      placeholder="Yes/No"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Which platforms have you used?
                    </label>
                    <input
                      type="text"
                      name="platformsUsed"
                      value={formData.platformsUsed}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                      placeholder="Enter platforms"
                    />
                  </div>

                  <button
                    type="button"
                    onClick={handlePreviousStep}
                    className="w-full bg-gray-300 hover:bg-gray-400 px-6 py-2 font-semibold rounded-lg"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="w-full bg-[#f0703a] font-semibold text-white px-6 py-2 rounded-lg hover:bg-[#cc6235] transition duration-300"
                  >
                    Next
                  </button>
                </>
              )}
              {step === 3 && (
                <>
                  <div>
                    <label className="block text-gray-700 text-sm md:text-base font-medium mb-1">
                      Which feature of ours are you most excited about?
                    </label>
                    <select
                      name="likedFeatures"
                      value={formData.likedFeatures}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f0703a] focus:border-transparent"
                    >
                      <option value="">Select a feature</option>
                      <option value="Chatbot">24/7 Chatbot Support</option>
                      <option value="Sleep and Fitness Tracking">
                        Sleep & Fitness Tracking
                      </option>
                      <option value="Nutrition Tracking">
                        Nutrition Tracking
                      </option>
                      <option value="Community">Community</option>
                      <option value="Personalized Reports">
                        Personalized Reports
                      </option>
                    </select>
                  </div>

                  <button
                    type="button"
                    onClick={handlePreviousStep}
                    className="w-full bg-gray-300 hover:bg-gray-400 font-semibold px-6 py-2 rounded-lg"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="w-full bg-[#f0703a] text-white px-4 py-2 rounded-lg hover:bg-[#cc6235] transition duration-300"
                  >
                    Submit
                  </button>
                </>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default VolunteerForm;
