import Hero from "./Hero";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "../../index.css";
import WhyUs from "../WhyUs/WhyUs";
import depression from "../../assets/depression.png";
import alcohol from "../../assets/alcohol.png";
import gad from "../../assets/gad.png";
import ocd from "../../assets/ocd.png";
import bipolar from "../../assets/bipolar.png";
import adultADHD from "../../assets/adhd.png";
import diag from "../../assets/Diagnosis.png";
import selfCare from "../../assets/self-care.png";
import community from "../../assets/community.png";
import socialAnxiety from "../../assets/social-anxiety.png";
import women from "../../assets/women.png";
import ptsd from "../../assets/ptsd.png";
import tobacco from "../../assets/tobacco.png";
import grief from "../../assets/grief.png";
import anxiety from "../../assets/anxiety.png";
import Testimonial from "./Testimonial";
import ResourcesSection from "./ResourcesSection";
import care from "../../assets/care.jpeg";

const scrollToBookSession = () => {
  const section = document.getElementById("free-session");
  section.scrollIntoView({ behavior: "smooth" });
};

// What's Bothering You Section
const Bothering = () => {
  const data = [
    {
      title: "Depression",
      description:
        "Struggling with ongoing sadness and feeling stuck? We're here to help.",
      label: "Learn More",
      icon: depression,
      link: "/depression",
    },
    {
      title: "Anxiety",
      description:
        "Is anxiety holding you back? We're here to help you find calm and confidence.",
      label: "Learn More",
      icon: anxiety,
      link: "/anxiety",
    },
    {
      title: "Alcohol Addiction",
      description:
        "Is alcohol affecting your life? We offer support and guidance.",
      label: "Learn More",
      icon: alcohol,
      link: "/addiction",
    },
    {
      title: "Generalised Anxiety Disorder (GAD)",
      description:
        "Feeling restless or worried constantly? Let's help you cope better.",
      label: "Learn More",
      icon: gad,
      link: "/gad",
    },
    {
      title: "Obsessive Compulsive Disorder (OCD)",
      description:
        "Experiencing unwanted thoughts or behaviors? Find ways to manage.",
      label: "Learn More",
      icon: ocd,
      link: "/ocd",
    },
    {
      title: "Bipolar Disorder",
      description:
        "Struggling with intense mood swings? We're here to offer care.",
      label: "Learn More",
      icon: bipolar,
      link: "bipolar-disorder",
    },
    {
      title: "Adult ADHD",
      description:
        "Difficulty focusing or staying organized? Let's find better solutions.",
      label: "Learn More",
      icon: adultADHD,
      link: "adhd",
    },
    {
      title: "Social Anxiety",
      description:
        "Do social situations make you anxious? We'll help you feel more confident.",
      label: "Learn More",
      icon: socialAnxiety,
      link: "/social-anxiety",
    },
    {
      title: "Women Health",
      description:
        "Hormonal or reproductive health concerns? Get the care you deserve.",
      label: "Learn More",
      icon: women,
      link: "/women-problems",
    },
    {
      title: "Tobacco Addiction",
      description:
        "Struggling to quit tobacco? We're here to guide you through recovery.",
      label: "Learn More",
      icon: tobacco,
      link: "/addiction",
    },
    {
      title: "Post-Traumatic Stress Disorder (PTSD)",
      description:
        "Past trauma affecting your present? We offer support to help you recover.",
      label: "Learn More",
      icon: ptsd,
      link: "ptsd",
    },
    {
      title: "Grief and Loss",
      description:
        "Coping with the loss of a loved one? We're here to support you.",
      label: "Learn More",
      icon: grief,
      link: "grief-and-loss",
    },
  ];

  return (
    <div className="w-full bg-[#feebc5] h-fit flex flex-col justify-center items-center px-4 py-10 lg:p-16 xl:p-20 font-sora">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-gray-700 text-center lg:mt-6">
        What is Bothering You?
      </h2>
      <p className="text-base md:text-lg text-gray-500 text-center mb-4 md:px-6">
        We're here to help you navigate through your mental health journey with
        care, support, and the right resources.
      </p>

      <div className="relative lg:w-10/12 w-full md:w-11/12 h-fit p-4 lg:px-5 md:px-10">
        <Swiper
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={3}
          navigation={{
            nextEl: ".custom-next-arrow",
            prevEl: ".custom-prev-arrow",
          }}
          breakpoints={{
            1025: {
              slidesPerView: 3,
            },
            915: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            },
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="bg-[#373f61] px-4 md:px-5 py-4 rounded-2xl shadow-md flex flex-col justify-start items-start min-h-[290px] md:min-h-[300px] lg:min-h-[320px] mx-4 space-y-4 relative">
                <div className="flex flex-col lg:flex-row lg:gap-3 md:gap-8">
                  {item.icon && (
                    <img
                      src={item.icon}
                      alt={`Icon for ${item.title}`}
                      className="w-[60px] md:w-[70px] lg:w-[60px]"
                    />
                  )}
                  <h2 className="text-lg lg:text-xl md:text-2xl text-gray-100 font-bold">
                    {item.title}
                  </h2>
                </div>

                <p className="text-[14px] lg:text-[16px] md:text-[17px] text-gray-300 mt-3">
                  {item.description}
                </p>

                <Link to={item.link}>
                  <button className="absolute bottom-4 md:bottom-6 left-4 md:left-5 lg:left-5 text-sm sm:text-base bg-[#f0703a] text-white px-2 sm:px-3 py-1.5 rounded-md font-semibold hover:bg-[#cc6235] transition-all duration-300">
                    {item.label}
                  </button>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Arrows with adjusted padding-x for mobile */}
        <div
          className="custom-prev-arrow absolute top-1/2 -left-2 sm:-left-4 transform -translate-y-1/2 cursor-pointer bg-[#f0703a] hover:bg-[#cc6235] 
              w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full"
        >
          <svg
            width="12" // Small size for mobile
            height="12"
            className="sm:w-5 sm:h-5 md:w-6 md:h-6" // Adjust SVG size for medium screens
            viewBox="4 4 16 16"
            fill="none"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M15 18l-6-6 6-6"></path>
          </svg>
        </div>

        <div
          className="custom-next-arrow absolute top-1/2 -right-2 sm:-right-4 transform -translate-y-1/2 cursor-pointer bg-[#f0703a] hover:bg-[#cc6235] 
              w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full"
        >
          <svg
            width="12" // Small size for mobile
            height="12"
            className="sm:w-5 sm:h-5 md:w-6 md:h-6" // Adjust SVG size for medium screens
            viewBox="4 4 16 16"
            fill="none"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9 6l6 6-6 6"></path>
          </svg>
        </div>
      </div>

      <div className="w-full px-6 md:px-10 mt-12">
        <h2 className="text-base lg:text-xl font-semibold text-center text-gray-700">
          We specialise in treating a wide range of mental health concerns. Get
          in touch to know more.
        </h2>
        <div className="w-full pb-10 px-6 md:px-10 flex justify-center">
          <div className="md:w-11/12 bg-white p-6 mt-10 rounded-2xl shadow-md flex flex-col lg:flex-row justify-between items-center">
            <div className="flex flex-col lg:flex-row items-center mb-6 lg:mb-0">
              <div className="lg:ml-8 text-center lg:text-left">
                <h4 className="text-lg font-semibold text-gray-700 py-1 md:pr-4">
                  Unsure about your problem?
                </h4>
                <p className="text-gray-500 text-base py-1 md:pr-4">
                  Talk to one of Rezen’s mental health experts to understand how
                  we can help.
                </p>
              </div>
            </div>

            <button
              onClick={scrollToBookSession}
              className="bg-[#f0703a] text-white px-3 md:px-4 py-2 lg:px-6 md:py-3.5 font-semibold text-sm rounded-md hover:bg-[#cc6235] transition-all duration-300"
            >
              GET CONNECTED FOR ASSESSMENT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//Healthcare Offerings Section
const OfferingsSection = () => {
  return (
    <div className="bg-[#fff6e3] py-10 md:py-12 lg:pt-20 xl:pt-24 padding-x">
      {/* Title and Description */}
      <div className="text-center mb-12 md:mb-16">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-700 mb-4">
          Our Healthcare Offerings
        </h2>
        <p className="text-base md:text-lg text-gray-500">
          We provide a holistic range of mental health services, designed to
          support your well-being every step of the way.
        </p>
      </div>

      {/* Offerings Section with responsive spacing */}
      <div className="mx-auto max-w-screen-2xl">
        {/* Diagnosis and Therapy - Image on Left, Text on Right */}
        <div className="flex flex-col md:flex-row items-center justify-center mb-12 md:mb-16 lg:mb-20 gap-6 md:gap-8 lg:gap-10">
          <div className="md:w-2/5 mb-0">
            <img
              src={diag} // Replace with your actual image path
              alt="Diagnosis and Therapy"
              className="w-full h-auto object-cover rounded-xl mx-auto"
            />
          </div>
          <div className="md:w-3/5 text-center md:text-left flex flex-col justify-center gap-4 md:gap-5 lg:gap-6 md:pl-8">
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-700">
              Diagnosis and Therapy
            </h3>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              Our in-house team provides expert diagnosis and therapy to ensure
              personalized care and mental well-being. We use evidence-based
              approaches tailored to each individual to create a customized
              treatment plan that addresses the root causes of mental health
              challenges.
            </p>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              With a focus on holistic care, we offer a range of therapies
              including cognitive behavioral therapy, mindfulness, and
              counseling to support long-term mental health improvement.
            </p>
            <a href="https://chat.whatsapp.com/FHud0xMwIdp10qcQIvGcDx">
              <button className="bg-[#f0703a] text-white py-3 px-7 text-base rounded hover:bg-[#cc6235] transition self-center md:self-start">
                Get Started
              </button>{" "}
            </a>
          </div>
        </div>

        {/* Self-care and Progress - Text on Left, Image on Right */}
        <div className="flex flex-col md:flex-row-reverse items-center justify-center mb-12 md:mb-16 lg:mb-20 gap-6 md:gap-8 lg:gap-10">
          <div className="md:w-2/5 mb-0">
            <img
              src={selfCare} // Replace with your actual image path
              alt="Self-care and Progress"
              className="w-full h-auto object-cover rounded-xl mx-auto"
            />
          </div>
          <div className="md:w-3/5 text-center md:text-left flex flex-col justify-center gap-4 md:gap-5 lg:gap-6 md:pr-8">
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-700">
              Self-care and Progress
            </h3>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              Track your self-care journey with our innovative dashboard that
              helps you monitor your mental health progress. We provide
              personalized tools such as mindfulness exercises, journaling
              prompts, and guided reflections to help you stay on track.
            </p>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              Our platform also offers goal setting and progress tracking
              features that empower you to achieve better mental health and
              well-being over time. Take control of your journey with self-care
              routines tailored to your needs.
            </p>
            {/* <a
              href="/app-prod-release.apk"
              download="app-prod-release.apk"
              className="bg-[#f0703a] text-white py-3 px-7 text-base rounded hover:bg-[#cc6235] transition self-center md:self-start"
            >
              {" "}
              Download
            </a> */}
          </div>
        </div>

        {/* Community - Image on Left, Text on Right */}
        <div className="flex flex-col md:flex-row items-center justify-center mb-12 md:mb-16 lg:mb-20 gap-6 md:gap-8 lg:gap-10">
          <div className="md:w-2/5 mb-0">
            <img
              src={community} // Replace with your actual image path
              alt="Community support"
              className="w-[110%] h-auto object-cover rounded-xl mx-auto"
            />
          </div>
          <div className="md:w-3/5 text-center md:text-left flex flex-col justify-center gap-4 md:gap-5 lg:gap-6 md:pl-8">
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-700">
              Community
            </h3>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              Join a vibrant, supportive community where individuals with
              similar mental health challenges come together to share their
              experiences and support each other. Our community offers group
              discussions, peer support, and access to expert advice from mental
              health professionals.
            </p>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              Build meaningful connections with others on a similar journey, and
              take advantage of our resources designed to foster a sense of
              belonging and collective growth.
            </p>
            <a href="https://chat.whatsapp.com/FHud0xMwIdp10qcQIvGcDx">
              <button className="bg-[#f0703a] text-white py-3 px-7 text-base rounded hover:bg-[#cc6235] transition self-center md:self-start">
                Join the Community
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewsSection = () => {
  const newsArticles = [
    {
      title: "How does Education Affect Mental Health?",
      author: "Dr. Nicola Williams, Ph.D",
      link: "https://www.news-medical.net/health/How-does-Education-Affect-Mental-Health.aspx",
      description:
        "Discover the powerful link between education and mental well-being, and how learning can influence emotional health.",
    },
    {
      title: "Analyzing the Stigma Surrounding Mental Health",
      author: "Sarah Moore",
      link: "https://www.news-medical.net/health/Analyzing-the-Stigma-Surrounding-Mental-Health.aspx",
      description:
        "A closer look at the causes of mental health stigma and its impact on individuals seeking support.",
    },
  ];

  return (
    <section className="bg-[#ffd8c2] py-16 md:py-20 lg:py-24 xl:py-36 padding-x">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 md:gap-10 lg:gap-20">
          {/* Left Side: Title, Description, and Button */}
          <div className="lg:col-span-1 flex flex-col justify-center gap-4 text-center lg:text-left">
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-2">
              In The <span className="text-[#e84a07]"> News </span>
            </h2>
            <p className="text-gray-600 leading-loose lg:mb-6 lg:mr-5">
              Stay updated with the latest developments, trends, and research in
              the field of mental health. Discover how the world is embracing
              mental wellness and how our platform is contributing to
              personalized care solutions.
            </p>
            <button className="text-[#e84a07] font-semibold hover:underline hover:text-[#c93c00fb] transition-all bg-transparent border-none cursor-pointer lg:text-left">
              View All News &rarr;
            </button>
          </div>

          {/* Right Side: News Articles */}
          <div className="lg:col-span-2 bg-[#ffd8c2]">
            <div className="space-y-6">
              {newsArticles.map((article, index) => (
                <a
                  key={index}
                  href={article.link}
                  className="bg-white p-6 shadow-lg rounded-2xl flex flex-col md:flex-row items-start md:items-center cursor-pointer transition-all duration-300 hover:shadow-xl hover:-translate-y-2 no-underline"
                >
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold text-[#e84a07] mt-2">
                      {article.title}
                    </h3>
                    <span className="block text-sm text-gray-500 mt-1">
                      {article.author}
                    </span>
                    <p className="text-gray-600 mt-2">{article.description}</p>
                  </div>

                  {/* Arrow Icon */}
                  <div className="text-[#e84a07] mt-4 md:mt-0 md:ml-4 self-start md:self-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FreeSession = () => {
  const highlights = [
    {
      title: "Selected Among 7000+ Startups",
      description:
        "Our startup, ReZen, was selected to present its innovative idea at a prestigious event, standing out among 7000+ competitors.",
    },
    {
      title: "2nd Position at RideHack",
      description:
        "ReZen secured 2nd place in RideHack, showcasing our innovative solutions to real-world challenges in mental health and wellness.",
    },
  ];

  return (
    <section
      id="free-session"
      className="bg-white text-center py-16 md:py-20 lg:py-24 padding-x"
    >
      <div className="max-w-4xl mx-auto mb-20 lg:mb-32">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-6">
          Your First Session is on Us
        </h2>
        <p className="text-base lg:text-lg text-gray-600 mb-10 lg:mb-14">
          Start your journey towards better mental health with no strings
          attached. Enjoy a complimentary first session and see how we can help.
        </p>
        <Link
          to="/book-free-session"
          className="bg-[#f0703a] text-white text-lg font-semibold py-3.5 px-12 rounded-md hover:bg-[#cc6235] transform hover:scale-110 transition-all duration-300"
        >
          Book Session Now
        </Link>
      </div>

      <section className="bg-[#e0ecd3] py-8 md:py-10 lg:py-12 rounded-lg px-6 sm:px-10 md:px-14">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-[#e84a07] mb-6">
            Company Highlights
          </h2>
          <div className="space-y-4">
            {highlights.map((highlight, index) => (
              <div
                key={index}
                className="flex items-center bg-[#f6f9f3] p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow hover:scale-105 cursor-pointer"
              >
                <div className="flex-grow">
                  <p className="text-sm font-semibold text-[#e84a07]">
                    {highlight.title}
                  </p>
                  <p className="text-gray-700 text-sm">
                    {highlight.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

const NeedHelp = () => {
  const [showForm, setShowForm] = useState(false);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  return (
    <div className="mt-12 py-16 md:py-20 lg:py-24 padding-x">
      {/* Text and Image Section */}
      <div className="grid lg:grid-cols-2 lg:gap-6">
        {/* Text part */}
        <div className="lg:w-full text-center lg:text-left">
          <h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-800 mb-4">
            Concerned about someone’s well-being?
          </h2>
          <p className="mt-2 text-sm lg:text-base text-gray-600 sm:text-md">
            Seeing someone you care about struggle can be difficult. Finding the
            right care is key. Our Care Consultant is here to guide you in
            getting the best support for yourself or a loved one.
          </p>

          {/* Conditional Button */}
          {!showForm && (
            <div className="mt-8 lg:mt-10">
              <button
                className="bg-[#f0703a] text-white px-5 py-2.5 rounded-md font-semibold hover:bg-[#cc6235]"
                onClick={handleButtonClick}
              >
                Connect with Us
              </button>
            </div>
          )}
        </div>

        {/* Image part */}
        <div className="flex justify-center lg:justify-end mt-6 lg:mt-0">
          <img
            src={care}
            alt="Care Consultant"
            className="rounded-full w-52 lg:w-64"
          />
        </div>
      </div>

      {showForm && (
        <div className="mt-12 p-6 bg-white shadow-lg rounded-lg border border-gray-200 max-w-lg mx-auto">
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-semibold text-gray-700">
                Name
              </label>
              <input
                type="text"
                placeholder="Your name"
                className="w-full mt-1 px-2 py-1.5 md:py-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#f0703a] placeholder:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700">
                Email
              </label>
              <input
                type="email"
                placeholder="Your email"
                className="w-full mt-1 px-2 py-1.5 md:py-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#f0703a] placeholder:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700">
                Mobile No.
              </label>
              <input
                type="tel"
                placeholder="Your mobile number"
                className="w-full mt-1 px-2 py-1.5 md:py-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#f0703a] placeholder:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-semibold text-gray-700">
                Age
              </label>
              <input
                type="number"
                placeholder="Age of Patient"
                className="w-full mt-1 px-2 py-1.5 md:py-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#f0703a] placeholder:text-sm"
              />
            </div>
            <div className="pt-4">
              <button
                type="submit"
                className="bg-[#f0703a] w-full text-white px-4 py-2 md:py-2.5 rounded-md font-semibold hover:bg-[#cc6235]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
const Assistance = () => {
  return (
    <div className="flex justify-center px-7 lg:px-4 py-7 bg-slate-200 text-gray-700">
      <p className="text-center text-sm md:text-base">
        If you need any assistance, feel free to contact us at{" "}
        <a
          href="mailto:contact@rezen.in"
          className="text-[#e84a07] font-semibold hover:underline"
        >
          contact@rezen.in
        </a>{" "}
        or{" "}
        <a
          href="tel:+918409412228"
          className="text-[#e84a07] font-semibold hover:underline"
        >
          +918409412228
        </a>
        . We’re here to support you every step of the way.
      </p>
    </div>
  );
};

function Home() {
  return (
    <main className="overflow-x-hidden font-sora">
      <Hero />
      <WhyUs />
      <Bothering />
      <OfferingsSection />
      <FreeSession />
      <ResourcesSection />
      <NewsSection />
      <Testimonial />
      <NeedHelp />
      <Assistance />
    </main>
  );
}

export default Home;
